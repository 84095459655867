<template>
    <div class="wrapper" style="">
        <img class="blackpink onlyDesktop" src="/images/blackpink.png">
        <img class="blackpink onlyMobile" src="/images/blackpink_mob.png">
        <section class="instructions">
            <img class="line onlyDesktop" src="/images/instroline.svg">
            <img class="line onlyMobile" src="/images/instroline_mob.svg">
            <div class="instruction" v-for="(point, index) in items">
                <p v-html="point.text"></p>
            </div>
            <div class="shops-buttons">
                <a target="_blank" v-on:click="iosClick()" href="https://apps.apple.com/ru/app/apple-store/id1453025777">
                    <img src="/images/app-store.svg">
                </a>
                <a target="_blank" v-on:click="googleBlockInfo()" href="https://docs.google.com/uc?export=download&id=1l8vTyRTsoJz-nM9qtPwzo2YyHf5BFV0u">
                    <img src="/images/google-play.svg">
                </a>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "instructions",
        components: {
        },
        data() {
            let basePath = 'components.instructions';
            let items = this.getLocalizedCollection(basePath, 'items');

            return {
                items
            }
        },
        methods: {
            googleBlockInfo() {
                // e.preventDefault();
                // console.log(1);
                // const text = "У нас возникли небольшие трудности с приложением на Android. Компания Google временно заморозила страницу приложения и покупки в нём. Стараемся разрешить эту проблему как можно скорее.\n\nВы можете установить приложение вручную через APK-файл. Извиняемся за накладку и благодарим интерес к проекту :)";
                // if (confirm(text)) {
                //     window.location.replace("/MHT-v1.0.51.apk")
                // }
                ga('send', 'event', 'downloadLink', 'android');
            },
            iosClick() {
                console.log(2);
                ga('send', 'event', 'downloadLink', 'ios');
            }
        }
    }
</script>

<style lang="scss">

    .wrapper {
        position: relative;
        z-index: 5;

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
           top: -100px;
        }
    }

    .blackpink {
        position: absolute;
        top: -300px;
        left: 0;

        @media all and (max-width: $screen-phone-max-width) {
            top: -100vw;
            width: 100%;
        }
    }

    .instructions {
        position: relative;
        height: 445px;
        z-index: 5;

        @media all and (max-width: $screen-phone-max-width) {
            height: 130vw;
        }
        
        .line {
            position: absolute;

            top: -128px;
            left: 70px;

            width: 879px;

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                width: 700px;
                left: 93px;
                top: -110px;
            }
            

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
               top: 16px;
               left: 24px;
               width: 516px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 11.4vw;
                left: 3.8vw;
                width: 16.4vw;
            }
        }

        .instruction:nth-of-type(1) {
            top: -48px;

            p {
                width: 299px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 175px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 46.6vw;
                }
            }

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                top: -30px;
                left: 62px;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 70px;
                left: 0;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 7.2vw;
                left: 26.13vw;
            }
        }

        .instruction:nth-of-type(2) {
            top: -48px;
            left: 410px;

            p {
                width: 273px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 207px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 55.2vw;
                }
            }

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                top: -30px;
                left: 379px;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
               top: 70px;
               left: 239px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 69vw;
                left: 26.13vw;
            }
        }

        .instruction:nth-of-type(3) {
            top: -48px;
            left: 800px;

            p {
                width: 353px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 198px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 52.8vw;
                }
            }

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                top: -30px;
                left: 718px;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 70px;
                left: 488px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 95vw;
                left: 26.13vw;
            }
        }

        .shops-buttons {
            position: absolute;
            top: 100px;

            @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                top: 50px;
                width: 170px;
                left: 61px;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 164px;
                width: 170px;
                left: 0;
            }

            a {
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                }

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 160px;
                    height: 48px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 42.68vw;
                    height: 12.8vw;
                }
            }

            a:nth-of-type(odd) {
                margin-bottom: 24px;

                @media all and (min-width: $screen-desktop-small-min-width) and (max-width: $screen-desktop-small-max-width) {
                    margin-bottom: 18px;
                }

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    margin-bottom: 14px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    margin-bottom: 3.73vw;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 27.86vw;
                left: 26.13vw;
                bottom: unset;
            }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            height: 290px;
        }

        @media all and (max-width: $screen-phone-max-width) {
        }
    }

    .instruction {
        position: absolute;

        img {
            position: absolute;
            width: 41px;
            height: 58px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                width: 24px;
                height: 34px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 6.4vw;
                height: 9.06vw;
            }
        }

        p {
            font-size: $desktop-font-size-6;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 46px;
            letter-spacing: normal;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-desktop-small-max-width) {
                font-size: 21px;
                line-height: 29px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                font-size: $phone-font-size-6;
                line-height: 8vw;
            }
        }
    }
</style>